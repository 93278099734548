import React from 'react';
import { ManageQuestions } from './ManageQuestions';

const Admin = ({ token }) => {
  return (
    <section>
      <ManageQuestions />
    </section>
  );
};

export { Admin };
