import { Global } from '@emotion/core';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { globalStyles } from './AppStyle';
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import { Admin } from './pages/admin/Admin';
import { Home } from './pages/home/Home';
import { Login } from './pages/Login';
import { Quiz } from './pages/quiz/Quiz';

const App = () => {
  return (
    <BrowserRouter>
      <Global styles={globalStyles} />
      <section>
        <PublicRoute path="/" component={Login} exact />
        <PrivateRoute path="/home" component={Home} exact />
        <PrivateRoute path="/quiz/:id" component={Quiz} exact />
        <PrivateRoute path="/admin" component={Admin} exact />
      </section>
    </BrowserRouter>
  );
};

export default App;
