/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, COLOR_PALETTE } from './../../AppStyle';
import { axios } from './../../utils/axios';

const Home = () => {
  const history = useHistory();
  const [stats, setStats] = useState(null);

  useEffect(() => {
    axios.get(`/api/stats`).then((result) => setStats(result.data));
  }, []);

  const startTraining = () => {
    axios
      .get(`/api/start-training`)
      .then((response) => history.push(`/quiz/${response.data.id}`));
  };

  const startExam = () => {
    axios
      .get(`/api/start-exam`)
      .then((response) => history.push(`/quiz/${response.data.id}`));
  };

  const logout = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  const formatDate = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY, hh:mm:ss');
  };

  const getScore = (result) => {
    if (result <= 8) {
      return '2.0';
    }

    if (result <= 11) {
      return '2.5';
    }

    if (result === 12) {
      return '3.0';
    }

    if (result <= 15) {
      return '3.5';
    }

    if (result === 16) {
      return '4.0';
    }

    if (result <= 19) {
      return '4.5';
    }

    if (result === 20) {
      return '5.0';
    }
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 60%;
        flex-direction: column;
        margin: auto auto;
      `}
    >
      {stats !== null && (
        <div
          css={css`
            width: 100%;
            text-align: left;
            margin-bottom: 100px;
          `}
        >
          <h1>{stats.user.name}</h1>
          <h4>{stats.user.email}</h4>
          {stats !== null && stats.results.length !== 0 && (
            <div>
              <div>Twoje statystyki:</div>
              <hr />
              {stats.results.map((quiz, i) => (
                <div key={i}>
                  <span
                    css={css`
                      display: inline-block;
                      width: 200px;
                    `}
                  >
                    {formatDate(quiz.startDate)}
                  </span>
                  <span
                    css={css`
                      display: inline-block;
                      width: 100px;
                      display: none;
                    `}
                  >
                    {quiz.type === 'exam' ? 'Egzamin' : 'Trening'}
                  </span>

                  <span
                    css={css`
                      display: inline-block;
                      width: 100px;
                    `}
                  >
                    {quiz.result} / {quiz.results.length}
                  </span>

                  <span
                    css={css`
                      display: inline-block;
                      width: 100px;
                    `}
                  >
                    Ocena: {getScore(quiz.result)}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {false && (
        <Button
          css={css`
            margin-bottom: 20px;
          `}
          onClick={startTraining}
        >
          Zacznij terning
        </Button>
      )}

      {stats !== null && stats.results.length === 0 && (
        <Button
          css={css`
            margin-bottom: 30px;
            background-color: ${COLOR_PALETTE.pastel_red};
          `}
          onClick={startExam}
        >
          Zacznij egzamin
        </Button>
      )}

      <Button onClick={logout}>Wyloguj</Button>
    </div>
  );
};

export { Home };
