/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, COLOR_PALETTE } from '../AppStyle';
import { auth } from './../utils/api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);

  const history = useHistory();

  const handleLogin = () => {
    const token = btoa(email);

    auth(token)
      .then((authorized) => {
        if (authorized) {
          localStorage.setItem('token', token);
          history.push('/home');
        } else {
          setHasError(true);
        }
      })
      .catch(() => setHasError(true));
  };

  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
      `}
    >
      <div
        css={css`
          border: 2px solid ${COLOR_PALETTE.pastel_green};
          border-radius: 2px;
          padding: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        `}
      >
        <label
          css={css`
            font-size: 30px;
            display: block;
          `}
        >
          Adres email:
        </label>
        <input
          css={css`
            font-size: 30px;
            display: block;
            padding: 10px;
            border-radius: 2px;
            border: 2px solid ${COLOR_PALETTE.pastel_green};
            width: 500px;
          `}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="xxxxx-CKP@kozminski.edu.pl"
        />

        {hasError && (
          <div
            css={css`
              color: ${COLOR_PALETTE.pastel_red};
            `}
          >
            Niepoprawne dane
          </div>
        )}
        <Button
          onClick={handleLogin}
          css={css`
            margin-top: 10px;
          `}
        >
          Zaloguj
        </Button>
      </div>
    </section>
  );
};

export { Login };
