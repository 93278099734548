/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useCallback, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, COLOR_PALETTE, PASTEL_PALETTE } from '../../AppStyle';
import { axios } from '../../utils/axios';
import { logger } from '../../utils/logger';
import { Result } from './Result';

const padNum = (num) => {
  return num <= 9 ? `0${num}` : num;
};

const msToTime = (milliseconds) => {
  const ms = milliseconds % 1000;
  milliseconds = (milliseconds - ms) / 1000;
  const secs = milliseconds % 60;
  milliseconds = (milliseconds - secs) / 60;
  const mins = milliseconds % 60;
  // eslint-disable-next-line
  const hrs = (milliseconds - mins) / 60;

  return `${padNum(mins)}:${padNum(secs)}`;
};

const initialState = {
  quiz: {
    _id: null,
    questions: [],
  },
  results: [],
  answers: {},
  finalResult: null,
  showResults: false,
  timeLeft: null,
  finished: false,
  pending: false,
};

const ACTIONS = {
  MARK_ANSWER: 'MARK_ANSWER',
  SET_RESULTS: 'SET_RESULTS',
  SET_QUIZ: 'SET_QUIZ',
  SET_FINAL_RESULT: 'SET_FINAL_RESULT',
  TOGGLE_RESULTS: 'TOGGLE_RESULTS',
  SET_ERROR: 'SET_ERROR',
  SET_TIME_LEFT: 'SET_TIME_LEFT',
  FINISH_QUIZ: 'FINISH_QUIZ',
  SET_PENDING_RESULTS: 'SET_PENDING_RESULTS',
};

const reducer = (state, action) => {
  let output;

  switch (action.type) {
    case ACTIONS.MARK_ANSWER:
      output = {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.questionId]: action.payload.answerIndex,
        },
      };
      break;

    case ACTIONS.SET_RESULTS:
      output = {
        ...state,
        results: action.payload,
      };
      break;

    case ACTIONS.SET_ERROR:
      output = {
        ...state,
        error: action.payload,
      };
      break;

    case ACTIONS.SET_TIME_LEFT:
      output = {
        ...state,
        timeLeft: action.payload,
      };
      break;

    case ACTIONS.FINISH_QUIZ:
      output = {
        ...state,
        finished: true,
      };
      break;

    case ACTIONS.SET_PENDING_RESULTS:
      output = {
        ...state,
        pending: true,
      };
      break;

    case ACTIONS.SET_QUIZ:
      output = {
        ...state,
        quiz: action.payload,
      };
      break;

    case ACTIONS.SET_FINAL_RESULT:
      output = {
        ...state,
        finalResult: action.payload,
      };
      break;

    case ACTIONS.TOGGLE_RESULTS:
      output = {
        ...state,
        showResults: !state.showResults,
      };
      break;

    default:
      output = state;
      break;
  }
  return output;
};

const Quiz = () => {
  const [state, dispatch] = useReducer(logger(reducer), initialState);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (state.finalResult === null) {
      setTimeout(
        () =>
          axios.get(`/api/quiz/${id}/time-left`).then((response) => {
            if (response.data.time <= 0) {
              dispatch({
                type: ACTIONS.FINISH_QUIZ,
              });
            } else {
              dispatch({
                type: ACTIONS.SET_TIME_LEFT,
                payload: response.data.time,
              });
            }
          }),
        900
      );
    }
  }, [state.timeLeft, state.finalResult, id]);

  useEffect(() => {
    axios.get(`/api/quiz/${id}`).then((response) => {
      if (response.data.error) {
        dispatch({
          type: ACTIONS.SET_ERROR,
          payload: response.data.error,
        });
      } else {
        if (response.data.results) {
          dispatch({
            type: ACTIONS.SET_RESULTS,
            payload: response.data.results,
          });
        }

        dispatch({
          type: ACTIONS.SET_QUIZ,
          payload: response.data,
        });
      }
    });
  }, [id]);

  useEffect(() => {
    if (state.results.length) {
      dispatch({
        type: ACTIONS.SET_FINAL_RESULT,
        payload: state.results.filter((result) => result.result).length,
      });
    }
  }, [state.results]);

  useEffect(() => {
    if (state.finalResult !== null) {
      dispatch({
        type: ACTIONS.TOGGLE_RESULTS,
      });
    }
  }, [state.finalResult]);

  const markAnswer = (questionId, answerIndex) => {
    if (state.results.length === 0) {
      dispatch({
        type: ACTIONS.MARK_ANSWER,
        payload: { questionId, answerIndex },
      });
    }
  };

  const handleAnswers = useCallback(() => {
    if (state.error || state.finalResult !== null) {
      history.push('/home');
    } else {
      const answers = state.quiz.questions.reduce((acc, question) => {
        return {
          ...acc,
          [question._id]: state.answers[question._id] || '',
        };
      }, {});

      axios
        .post(`/api/answers/${id}`, answers)
        .then((response) =>
          dispatch({ type: ACTIONS.SET_RESULTS, payload: response.data })
        );
    }
  }, [
    history,
    id,
    state.answers,
    state.error,
    state.finalResult,
    state.quiz.questions,
  ]);

  useEffect(() => {
    if (state.finished && !state.pending) {
      dispatch({ type: ACTIONS.SET_PENDING_RESULTS });
      handleAnswers();
    }
  }, [state.finished, state.pending, handleAnswers]);

  const isGoodAnswer = (questionId, answerText) => {
    const { results, answers } = state;

    return results.some(
      (questions) =>
        questionId === questions._id &&
        questions.result &&
        answers[questionId] === answerText
    );
  };

  const isBadAnswer = (questionId, answerText) => {
    const { results, answers } = state;
    return results.some(
      (question) =>
        questionId === question._id &&
        !question.result &&
        question.answers.find((ans) => ans.text === answerText)?.isProper ===
          false &&
        answers[questionId] === answerText
    );
  };

  const shouldMarkAnswer = (questionId, answerText) => {
    const { results } = state;
    return results.some(
      (question) =>
        questionId === question._id &&
        !question.result &&
        question.answers.find((ans) => ans.text === answerText)?.isProper
    );
  };

  const isAnswerChecked = (questionId, answerText) => {
    const { answers } = state;
    return answers[questionId] === answerText;
  };

  return (
    <section
      css={css`
        height: 100vh;
        background-color: ${COLOR_PALETTE.background_graphite};
        overflow: auto;
      `}
    >
      {state.error && (
        <h1
          css={css`
            text-align: center;
            padding: 40px;
            font-size: 60px;
            color: ${COLOR_PALETTE.pastel_red};
          `}
        >
          {state.error}
        </h1>
      )}
      {state.showResults && (
        <Result
          result={state.finalResult}
          toggleResults={() => dispatch({ type: ACTIONS.TOGGLE_RESULTS })}
        />
      )}
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 50px;
          box-sizing: border-box;
        `}
      >
        {state.quiz.questions.map((question, i) => (
          <div
            css={css`
              padding: 20px;
              border-bottom: 1px solid ${COLOR_PALETTE.background_navy_blue};

              ${i === state.quiz.questions.length - 1 &&
              css`
                border-bottom: 0;
              `}
            `}
            key={question._id}
          >
            <div
              css={css`
                font-weight: bold;
                margin-bottom: 20px;
              `}
            >
              {i + 1}. {question.question}
            </div>
            <ol type="A">
              {question.answers.map((answer) => (
                <li
                  css={css`
                  padding: 5px;

                  &:hover {
                    cursor: pointer;
                    opacity: 0.5;
                  }

                  ${
                    isAnswerChecked(question._id, answer.text) &&
                    css`
                      text-decoration: underline;
                      color: ${COLOR_PALETTE.pastel_orange};
                    `
                  }

                  ${
                    shouldMarkAnswer(question._id, answer.text) &&
                    css`
                      color: ${PASTEL_PALETTE.green};
                      font-weight: bold;
                    `
                  }

                  ${
                    isBadAnswer(question._id, answer.text) &&
                    css`
                      color: ${PASTEL_PALETTE.red};
                      font-weight: bold;
                    `
                  }

                  ${
                    isGoodAnswer(question._id, answer.text) &&
                    css`
                      color: ${PASTEL_PALETTE.green};
                      font-weight: bold;
                    `
                  }
              `}
                  key={answer.text}
                  onClick={() => markAnswer(question._id, answer.text)}
                >
                  {answer.text}
                </li>
              ))}
            </ol>
          </div>
        ))}

        <Button onClick={handleAnswers}>
          {state.finalResult !== null ? 'zakończ sesję' : 'zakończ test'}{' '}
        </Button>

        {state.timeLeft !== null && state.finalResult === null && (
          <div
            css={css`
              position: fixed;
              top: 30px;
              right: 30px;
              background-color: ${COLOR_PALETTE.pastel_red};
              padding: 10px;
              border-radius: 2px;
              width: 150px;
              text-align: center;
            `}
          >
            <span
              css={css`
                font-weight: bold;
                text-shadow: 1px 1px 0 #0f0f0f;
                color: ${COLOR_PALETTE.font_white};
                font-size: 40px;
              `}
            >
              {msToTime(state.timeLeft)}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export { Quiz };
