import { css } from '@emotion/core';
import styled from '@emotion/styled';

const COLOR_PALETTE = {
  background_blue: '#325785',
  background_dark_blue: '#161B20',
  background_graphite: '#1F262B',
  background_navy_blue: '#0F1316',
  background_gray: '#555555',
  font_blue: '#5191E2',
  font_white: '#ffffff',
  pastel_green: '#7DCBBC',
  pastel_orange: '#DFAB52',
  pastel_yellow: '#EFE171',
  pastel_pink: '#E2C2A4',
  pastel_red: '#E57C67',
  pastel_blue: '#07519C',
};

const PASTEL_PALETTE = {
  blue: '#1F77B4',
  orange: '#FF7F0F',
  green: '#2DA02D',
  red: '#D62829',
  violet: '#9467BD',
  brown: '#8C574B',
  pink: '#E377C2',
  grey: '#7F7F7F',
  yellow: '#BCBD23',
  turquoise: '#14BECE',
};

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;600;700&display=swap');
  html,
  body {
    font-family: 'Prompt', sans-serif;
    overflow: hidden;
    color: ${COLOR_PALETTE.font_white};
    background-color: ${COLOR_PALETTE.background_dark_blue};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }
  input,
  textarea,
  select {
    font-family: 'Prompt', sans-serif;
  }
  * {
    box-sizing: border-box;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  font-weight: bold;
  text-align: center;
  color: ${COLOR_PALETTE.font_white};
  background-color: ${COLOR_PALETTE.pastel_green};
  border-radius: 2px;
  border: 0;
  padding: 10px;
  text-transform: uppercase;
  font-size: 24px;
  text-shadow: 1px 1px 0 #0f0f0f;

  &:hover {
    cursor: pointer;
    background-color: ${COLOR_PALETTE.pastel_blue};
  }
`;

const breakpoints = [576, 768, 992, 1200, 1800, 2200];

const mqMin = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
const mqMax = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export { COLOR_PALETTE, PASTEL_PALETTE, mqMin, mqMax, globalStyles, Button };
