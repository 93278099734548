import React, { useEffect } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { isExact } = useRouteMatch();

  useEffect(() => {
    if (isExact && localStorage.getItem('token')) {
      history.push('/home');
    }
  }, [history, isExact]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
export { PublicRoute };
