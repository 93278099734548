/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Button, COLOR_PALETTE } from '../../AppStyle';

const Result = ({ result, toggleResults }) => {
  return (
    <Fragment>
      <div
        onClick={toggleResults}
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: #000;
          opacity: 0.5;
          z-index: 99;
        `}
      ></div>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto auto;
          width: 400px;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          z-index: 100;
          color: ${COLOR_PALETTE.background_dark_blue};
          background-color: ${COLOR_PALETTE.font_white};
          border-radius: 2px;
          padding: 20px;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            font-size: 30px;
            margin-bottom: 30px;
          `}
        >
          Twój wynik: {result}
        </div>
        <Button onClick={toggleResults}>zobacz odpowiedzi</Button>
      </div>
    </Fragment>
  );
};

export { Result };
