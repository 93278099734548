/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useForm } from 'react-hook-form';

const ManageQuestions = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const request = {};
    request.question = data.question.trim();
    request.answers = [
      { text: data.ansA.trim(), isProper: data.ansAisProper },
      { text: data.ansB.trim(), isProper: data.ansBisProper },
      { text: data.ansC.trim(), isProper: data.ansCisProper },
      { text: data.ansD.trim(), isProper: data.ansDisProper },
    ];

    fetch('/api/questions:add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then((r) => r.json())
      .then(console.warn);
  };

  return (
    <section
      css={css`
        padding: 20px;
        border-radius: 2px;
        border: 1px solid #ccc;
        margin: 30px;

        label {
          display: inline-block;
          width: 80px;
        }

        .input {
          padding: 5px;
          border-radius: 2px;
          border: 1px solid #ccc;
          width: 800px;
        }

        form div {
          margin: 5px 0;
        }
      `}
    >
      <div>Dodaj pytanie</div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>Pytanie</label>
            <input className="input" name="question" ref={register} />
          </div>
          <div>
            <label>a)</label>
            <input type="checkbox" name="ansAisProper" ref={register} />
            <input className="input" name="ansA" ref={register} />
          </div>
          <div>
            <label>b)</label>
            <input type="checkbox" name="ansBisProper" ref={register} />
            <input className="input" name="ansB" ref={register} />
          </div>
          <div>
            <label>c)</label>
            <input type="checkbox" name="ansCisProper" ref={register} />
            <input className="input" name="ansC" ref={register} />
          </div>
          <div>
            <label>d)</label>
            <input type="checkbox" name="ansDisProper" ref={register} />
            <input className="input" name="ansD" ref={register} />
          </div>

          <button type="submit">Dodaj</button>
        </form>
      </div>
    </section>
  );
};

export { ManageQuestions };
