import pureAxios from 'axios';

pureAxios.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Basic ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

pureAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response?.status) {
      window.location.href = window.location.origin;
    } else {
      return Promise.reject(error);
    }
  }
);

export { pureAxios as axios };
